import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import withRoot from "../withRoot";
import MainInvite from "../components/MainInvite";
import WelcomeTab from "../components/WelcomeTab";
import UsefulTab from "../components/UsefulTab";
import ContactTab from "../components/ContactTab";
import RegistryTab from "../components/RegistryTab";
import Activities from "../components/Activities";
import Invite from "../components/Invite";
import InviteBanner from "../components/InviteBanner";
import CardShadow from "../components/CardShadow";
import MapTab from "../components/MapTab";
import Menu from "../components/Menu";
import qs from "query-string";
import request from "superagent";

const imageRatio = 0.475;
const baseWidth = 320;
const wideWidth = 600;

const styles = theme => ({
  "@global": {
    // MUI typography elements use REMs, so you can scale the global
    // font size by setting the font-size on the <html> element.
    html: {
      fontSize: 14,
      [theme.breakpoints.up("sm")]: {
        fontSize: 14
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16
      }
    }
  },
  root: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  TitleWrapper: {
    marginTop: theme.spacing(5),
  },
  LangBar: {
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    margin: "0 20px",
    position: "sticky",
    top: theme.spacing(2),
    zIndex: 2000
  },
  LangToggle: {
    cursor: "pointer"
  },
  Details: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(1)
    }
  },
  Tabs: {
    display: "none",
    position: "sticky",
    backgroundColor: "white",
    top: 0,
    paddingTop: theme.spacing(2),
    zIndex: 1000,
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  Menu: {
    display: "block",
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  TabBodyMob: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  TabBody: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    },
    maxWidth: 600,
    minHeight: 600,
    width: "100%",
    alignSelf: "center",
    zIndex: 1
  },
  InviteWrapper: {
    alignSelf: "center",
    backgroundColor: "transparent",
    position: "relative",
    width: `${baseWidth}px`,
    height: `${baseWidth * imageRatio}px`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: `${wideWidth}px`,
      height: `${wideWidth * imageRatio}px`
    }
  },
  Invite: {
    right: "0",
    top: "5%",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    opacity: 1
  },
  Shadow: {
    overflow: "hidden",
    right: "-20px",
    top: 0,
    height: "61.5%",
    position: "absolute",
    width: "20px",
    [theme.breakpoints.up("md")]: {
      width: "27px",
      right: "-27px"
    }
  },
  Button: {
    borderRadius: 0
  }
});

function titleSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return "Flo and Woody's wedding";
    case "SPANISH":
      return "Casamiento Woody y Flo";
    default:
      return "Flo and Woody's wedding";
  }
}

function langSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return "SPANISH";
    case "SPANISH":
      return "ENGLISH";
    default:
      return "ENGLISH";
  }
}

function flagSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return " No hablo inglés";
    case "SPANISH":
      return "I don't speak spanish";
    default:
      return "I don't speak spanish";
  }
}

function tabsSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return [
        { title: "RSVP", component: null },
        { title: "Schedule", component: Activities },
        { title: "Useful information", component: null },
        { title: "Maps", component: MapTab },
        { title: "Contact", component: null },
        { title: "Gift list", component: RegistryTab }
      ];
    case "SPANISH":
      return [
        { title: "Bienvenido", component: WelcomeTab },
        { title: "Programa", component: Activities },
        { title: "Información útil", component: null },
        { title: "Maps", component: MapTab },
        { title: "Contacto", component: null },
        { title: "Registro", component: RegistryTab }
      ];
    default:
      return "[]";
  }
}

class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      lang: "ENGLISH",
      tab: 1,
      rsvping: false,
      menuOpen: false,
      fetching: true,
      sessionReply: false
    };
  }

  componentDidMount() {
    const queryString = qs.parse(this.props.location.search);
    const hash = queryString.hash;
    const row = queryString.row;
    request
      .get(
        `https://s3g1w1nqxd.execute-api.us-east-1.amazonaws.com/dev/guest-action/?action=RSPV_VIEW&hash=${hash}&row=${row}`
      ).then(res => res);
    if (!row) return this.setState({ rsvpError: true});
    request
      .get(
        `https://ixlcgi3o4d.execute-api.eu-west-2.amazonaws.com/dev/get-rsvp/?hash=${hash}&row=${row}`
      )
      .then(res => {
        this.setState({
          fetching: false,
          reply: {
            replied: res.body.message[0].replied === "TRUE",
            can: res.body.message[0].rsvpyes,
            cannot: res.body.message[0].rsvpnope
          }
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({
          rsvpError: true
        });
      });
  }

  handleRSVPing() {
    this.setState({
      rsvping: true
    });
  }

  handleRSVPSubmit(yes, nope, anything) {
    const queryString = qs.parse(this.props.location.search);
    const row = queryString.row;
    console.log(anything)
    request
      .get(
        `https://ixlcgi3o4d.execute-api.eu-west-2.amazonaws.com/dev/update-rsvp/?row=${row}&yes=${yes}&nope=${nope}&anything=${anything}`
      )
      .then(res => {
        this.setState({ sessionReply: true })
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  toggleLang() {
    const { lang } = this.state;
    this.setState({
      lang: langSwitcher(lang)
    });
  }

  toggleMenuOpen() {
    const { menuOpen } = this.state;
    this.setState({
      menuOpen: !menuOpen
    });
  }

  handleTabChange(event, tab) {
    this.setState({ menuOpen: false, tab });
  }

  render() {
    const { classes } = this.props;
    const { lang, tab, menuOpen, fetching, reply, sessionReply, rsvping, rsvpError } = this.state;
    const queryString = qs.parse(this.props.location.search);
    if (!rsvping) return <MainInvite  name={queryString.name || null} rsvping={rsvping} handleRSVPing={this.handleRSVPing.bind(this)} />
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{titleSwitcher(lang)}</title>
        </Helmet>
        <Typography className={classes.TitleWrapper} variant="h2" gutterBottom>Flo and Hugo</Typography>
        <div className={classes.InviteWrapper}>
          <div className={classes.Invite}>
            <InviteBanner />
          </div>
        </div>
        <div className={classes.Details}>
          <Typography variant="body1">4.30pm Saturday March 7th, 2020</Typography>
          <Typography variant="body1">
            Capilla Santa Teresita, then Parador La Huella
          </Typography>
          <Typography variant="body1" gutterBottom>
            José Ignacio, Uruguay
          </Typography>
        </div>
        <div className={classes.Tabs}>
          <Tabs
            value={tab}
            onChange={this.handleTabChange.bind(this)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {tabsSwitcher(lang).map(tab => (
              <Tab key={tab.title} label={tab.title} />
            ))}
          </Tabs>
        </div>
        <div className={classes.TabBody}>
        {tab === 0 ? <WelcomeTab
          lang={lang}
          fetching={fetching}
          reply={reply || {}}
          rsvpError={rsvpError}
          sessionReply={sessionReply}
          handleSubmit={this.handleRSVPSubmit.bind(this)}
          /> : null}
          {tab === 1 ? <Activities lang={lang} /> : null}
          {tab === 2 ? <UsefulTab lang={lang} /> : null}
          {tab === 3 ? <MapTab lang={lang} /> : null}
          {tab === 4 ? <ContactTab lang={lang} /> : null}
          {tab === 5 ? <RegistryTab lang={lang} /> : null}
        </div>
        <div className={classes.TabBodyMob}>
        <WelcomeTab
          lang={lang}
          fetching={fetching}
          reply={reply || {}}
          rsvpError={rsvpError}
          sessionReply={sessionReply}
          handleSubmit={this.handleRSVPSubmit.bind(this)}
          />
          <Activities lang={lang} />
          <MapTab lang={lang} />
          <RegistryTab lang={lang} />
          <UsefulTab lang={lang} />
          <ContactTab lang={lang} />
        </div>
        <Typography>xoxox</Typography>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(Index));
