import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import classname from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MapZoomer from "./MapZoomer";

const styles = theme => ({
  MapTab: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10)
    },
    marginTop: theme.spacing(5),
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    flexDirection: "column",
    width: "100%"
  },
  MapSubTitle: {
    width: "100%",
    paddingLeft: theme.spacing(2)
  },
  MobileTitle: {
    display: "block",
    width: "100%",
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
});

class MapTab extends Component {
  constructor() {
    super();
    this.state = {
      zoom: 0
    };
  }
  toggleZoom() {
    const { zoom } = this.state;
    if (zoom === 5) return this.setState({ zoom: 0 });
    this.setState({ zoom: zoom + 1 });
  }
  render() {
    const { zoom } = this.state;
    const { classes, lang } = this.props;
    return (
      <div className={classes.MapTab}>
        <Typography className={classes.MobileTitle} gutterBottom variant="h5">
          Maps
        </Typography>
        <Typography className={classes.MapSubTitle} gutterBottom>Click to zoom, we've included some useful landmarks</Typography>
        <MapZoomer />
      </div>
    );
  }
}

export default withStyles(styles)(MapTab);
