import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const dataEnglish = [

];

const dataSpanish = [
];

const styles = theme => ({
  ContactTab: {
    margin: theme.spacing(1),
    display: "flex",
    textAlign: "left",
    alignItems: "left",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10)
    }
  },
  TextWrapper: {
    padding: theme.spacing(1)
  },
  MobileTitle: {
    display: "block",
    width: "100%",
    textAlign: "left",
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
});

function langSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return dataEnglish;
    case "SPANISH":
      return dataSpanish;
    default:
      return dataEnglish;
  }
}

class ContactTab extends Component {
  render() {
    const { classes, lang } = this.props;
    return (
      <div className={classes.ContactTab}>
        <Typography className={classes.MobileTitle} gutterBottom variant="h5">
          Contact info
        </Typography>
        <div className={classes.TextWrapper}>
          <Typography gutterBottom variant="body1"color="textSecondary">Contact Numbers:</Typography>
          <Typography gutterBottom variant="body1"color="textSecondary">Florencia: +598 (0) 97214244</Typography>
          <Typography gutterBottom variant="body1"color="textSecondary">Florencia: +44 7519 563281</Typography>
          <Typography gutterBottom variant="body1"color="textSecondary">Hugo: +44 7779 290961</Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ContactTab);
