import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import classname from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MapZoomer from "./MapZoomer";
import InfoContainerCenter from "./InfoContainerCenter";
import PaddedTypo from "./PaddedTypo";
import MUILink from "@material-ui/core/Link";

const dataEnglish = [
  {
    title: "Restaurant options",
    points: [
      "Mostrador Santa Teresita",
      "La Huella",
      "Popeye",
      "No me Olvides - Manantiales",
      "Flo - La Barra",
      "Elmo",
      "Fish Market - Manantiales",
      "L’auberge - Tea Room - Dulce de Leche Waffles"
    ]
  },
  {
    title: "Accomadation",
    points: [
      "We recommend that you stay in Jose Ignacio (where the wedding will take place",
      "Manantiales and La Barra are also good options, 20km and 15km from Jose Ignacio",
      "Below you can see a list of options where you can have a special rate by mentioning you are coming to the Woodhead - Guelfi wedding",
      "Also have a look at Airbnb, great alternatives for groups",
      "Posada Paradiso - Email: clodimet@clodimet.com",
      "Posada del Faro - Email: contact@posadadelfaro.com",
      "Hotel Anastasio (5 minute drive from Jose Ignacio, overlooking the beach) - Email: reservas@anastasiohotel.com.uy",
      "Casa Chic (small apartments with 1,2 or 3 bedrooms"
    ]
  }
];

const dataSpanish = [];

const imageRatio = 0.72;
const baseWidth = 280;
const wideWidth = 600;

const styles = theme => ({
  Useful: {
    marginTop: 80,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    textAlign: "justify",
    [theme.breakpoints.up("md")]: {
      marginBottom: 120
    },
  },
  InfoContainerWrapper: {
    display: "inline-block"
  },
  Taxi: {
    paddingBottom: theme.spacing(2)
  }
});

function langSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return dataEnglish;
    case "SPANISH":
      return dataSpanish;
    default:
      return dataEnglish;
  }
}

class Useful extends Component {
  render() {
    const { classes, lang } = this.props;
    return (
      <div className={classes.Useful}>
      <PaddedTypo gutterBottom variant="h5">
        Guide to Jose Ignacio
      </PaddedTypo>
      <PaddedTypo gutterBottom variant="body2">
        Shops
      </PaddedTypo>
        <ul>
          <li>Devoto - supermarket by the roundabout on the entrance to Jose Ignacio</li>
          <li>Panaderia - bakery and sandwich shop next door to Devoto</li>
          <li>Farmacia - also next door to Devoto, don’t forget to cream up</li>
          <li>Freddo - ice cream shop</li>
        </ul>
      <PaddedTypo gutterBottom variant="body2">
        Restaurants
      </PaddedTypo>
        <ul>
          <li>La Excusa - good coffee and breakfast</li>
          <li>Soho cafe - sandwich shop</li>
          <li>Marismo - good for an evening meal if you want to eat out</li>
          <li>Mostrador Santa Teresita - relaxed garden lunch with outside bar</li>
        </ul>
      <PaddedTypo gutterBottom variant="body2">
        Bars
      </PaddedTypo>
        <ul>
          <li>Luna Brava - roof-top bar next door to La Huella</li>
          <li>La Choza Del Mar - good to watch the sunset on the beach from Playa Manza</li>
        </ul>
      <PaddedTypo gutterBottom variant="body2">
        Taxis
      </PaddedTypo>
        <ul className={classes.Taxi}>
          <li>Taxi Diego - +598 99 815 643</li>
          <li>Taxi Jose Ignacio +598 99 569 941</li>
        </ul>
        <PaddedTypo gutterBottom variant="h5">
          Guide to some other places in Punta Del Este
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          La Punta
        </PaddedTypo>
          <ul>
            <li>Punta del Este port and walk along the piers.</li>
            <li>There are also great places to eat or have a coffee overlooking the Gorriti Island</li>
            <li>If you are interested in a longer (1 hour walk) you can go round “La punta”. We recommend you do this walk at sunset and watching where the Rio de la Plata and the Atlantic Ocean join </li>
            <li>Best Dulce de Leche churros - Churros Manolo</li>
          </ul>
        <PaddedTypo gutterBottom variant="body2">
          La Brava
        </PaddedTypo>
          <ul>
            <li>
              You can pick one of the 30+ stops along the coast for any of the ‘Brava’ beaches for a great swim and waves
            </li>
            <li>
              On stop 19 (in land) you can find{" "}
              <MUILink
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "http://laubergehotel.com"
                }
                className={classes.link}
              >
                L’Auberge Hotel
              </MUILink>
              , and have the best Dulce de Leche waffles and afternoon tea (really nice garden as well)
            </li>
          </ul>
        <PaddedTypo gutterBottom variant="body2">
          La Barra
        </PaddedTypo>
          <ul>
            <li>Trendy neighbourhood (used to be a fishermen’s town) with stores and restaurants along the high street</li>
            <li>Best places to eat: Flo Cafe (great sandwiches and salads), La Pasiva (Great chivitos), Freddo (Ice cream)</li>
          </ul>
        <PaddedTypo gutterBottom variant="body2">
          Manantiales
        </PaddedTypo>
          <ul>
            <li>Further along on the way to Jose Ignacio </li>
            <li>Another trendy neighbourhood with stores (Roberta Roller Rabbit - one of my favourite) and restaurants</li>
            <li>Best places to eat: No me olvides (pizzas), Fish Market (seafood), Volta (Ice Cream), Elmo Resto Bar (Amazing food and great ambience)</li>
          </ul>
        <PaddedTypo gutterBottom variant="body2">
          Garzon
        </PaddedTypo>
          <ul>
            <li>
              <MUILink
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://bodegagarzon.com/en/experiencia/garzon-reserva-tour"
                }
                className={classes.link}
              >
                Garzon Wineyard for a tour visit
              </MUILink>
            </li>
            <li>Walk around the town</li>
          </ul>
        <PaddedTypo gutterBottom variant="body2">
          If you are going to Buenos Aires,
          <MUILink
            target="_blank"
            rel="noopener noreferrer"
            href={
              "https://docs.google.com/document/d/16LZPt7fHRQBS084tfWVqDr8PvJbJs1eoD24sJj8AUpk/edit?usp=sharing"
            }
            className={classes.link}
          >
          {" "}here's some useful tips of where to hang out.
          </MUILink>
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="h5">
          Getting to Jose Ignacio
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          From Montevideo Airport:
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          You can take a bus (2 hour 30 minutes ride) or rent a car (2 hour
          ride) to Jose Ignacio. A car will be very useful in Punta del Este as
          distances are long outside Jose Ignacio.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Buses from Montevideo to Punta del Este and Jose Ignacio can be{"  "}
          <MUILink
            target="_blank"
            rel="noopener noreferrer"
            href={"http://www.cot.com.uy"}
            className={classes.link}
          >
            pre-booked online
          </MUILink>
          {"  "}or paid in cash to the bus driver.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Car rental options in Montevideo are all the big names:{"  "}
          <MUILink
            target="_blank"
            rel="noopener noreferrer"
            href={"https://hertz.com.uy/#/hertz-uruguay-alquiler-autos-inicio"}
            className={classes.link}
          >
            Hertz
          </MUILink>
          {",  "}
          <MUILink
            target="_blank"
            rel="noopener noreferrer"
            href={
              "https://www.sixt.com.ar/alquiler-autos/uruguay/montevideo/montevideo-aeropuerto"
            }
            className={classes.link}
          >
            Sixt
          </MUILink>
          {", or  "}
          <MUILink
            target="_blank"
            rel="noopener noreferrer"
            href={"https://www.avis.com.uy/webavis/servlet/home"}
            className={classes.link}
          >
            Avis
          </MUILink>
          {"."}
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          From Buenos Aires:
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Ferry (
          <MUILink
            target="_blank"
            rel="noopener noreferrer"
            href={"https://www.buquebus.com/english"}
            className={classes.link}
          >
            Buquebus
          </MUILink>
          ) from Buenos Aires to Montevideo Port. You can also get a Buquebus
          ferry to Colonia and drive to Jose Ignacio from there.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Flight from Buenos Aires – Aeroparque Airport (Not Ezeiza Airport) to
          Montevideo Airport. Flights take 40 minutes.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Flight from Buenos Aires – Ezeiza International Airport to Montevideo
          Airport, there is one daily flight from Ezeiza at 12:30 noon easy for
          connections with international flights.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          You can also fly from Buenos Aires to Punta del Este Airport. Flight takes
          55 minutes.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          If you need to rent a car from Punta Del Este Airport,{" "}
          <MUILink
            target="_blank"
            rel="noopener noreferrer"
            href={
              "https://www.europcar.com.uy/en/location/uruguay/punta-del-este"
            }
            className={classes.link}
          >
            Europcar
          </MUILink>{" "}
          have cars.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Suggested airlines: Aerolineas Aregentinas and Amazonas Airline.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="h5">
          Accommodation
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          We recommend you stay in Jose Ignacio where the wedding will take
          place and you can walk to the church and reception.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Manantiales and La Barra are also good options, 20km and 15km from
          Jose Ignacio.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Below you can find some options where you can have a special rate by
          mentioning you are coming to the Woodhead – Guelfi wedding
        </PaddedTypo>
          <ul>
            <li>
              <MUILink
                target="_blank"
                rel="noopener noreferrer"
                href={"http://posadaparadiso.com/joseignacio/home-villa-hotel/"}
                className={classes.link}
              >
                Posada Paradiso
              </MUILink>
              , email: clodimet@clodimet.com
            </li>
            <li>
              <MUILink
                target="_blank"
                rel="noopener noreferrer"
                href={"http://posadadelfaro.com/index.php"}
                className={classes.link}
              >
                Posada del Faro
              </MUILink>
              , email: contact@posadadelfaro.com
            </li>
            <li>
              <MUILink
                target="_blank"
                rel="noopener noreferrer"
                href={"http://anastasiohotel.com.uy"}
                className={classes.link}
              >
                Hotel & Beach Club Anastasio
              </MUILink>
              , 5 minute drive to Jose Ignacio overlooking the beach
            </li>
            <li>
              <MUILink
                target="_blank"
                rel="noopener noreferrer"
                href={"https://www.casa-chic.com/jose-ignacio-home/"}
                className={classes.link}
              >
                Casa Chic
              </MUILink>
              , These are self-catered 1,2 and 3 bedroom apartments with a
              shared pool. If you want to stay here get in touch with Chloe Woodhead.
            </li>
            <li>
              <MUILink
                target="_blank"
                rel="noopener noreferrer"
                href={"http://www.casasuaya.com/en/index/"}
                className={classes.link}
              >
                Casa Suaya
              </MUILink>
              , slightly outside Jose Ignacio but great if you have a car. (no discount for this one)
            </li>
          </ul>
        <PaddedTypo gutterBottom variant="body2">
          If you would like to stay in Punta del Este we recommend you stay at
          {" "}{
            <MUILink
              target="_blank"
              rel="noopener noreferrer"
              href={"http://laubergehotel.com/en/homepage/"}
              className={classes.link}>
              Hotel L'Auberge in San Rafael
            </MUILink>
          }. A very nice boutique hotel where you
          can get a special rate by mentioning you are coming to the Woodhead –
          Guelfi wedding. To book email directly to lauberge@laubergehotel.com and say Guelfi wedding. Even if
          you are not staying at L'Auberge, we highly recommend a visit for a
          great afternoon tea and the best Dulce de Leche Waffles.
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="h5">
          Other useful information
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Currency: Uruguayan Peso, US dollars are accepted
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Weather in March: 15 - 25 C
        </PaddedTypo>
        <PaddedTypo gutterBottom variant="body2">
          Dress code: Summer suits, ties optional. Party dresses. We recommend
          bringing comfortable shoes as you may need need to walk across sand.
        </PaddedTypo>
      </div>
    );
  }
}

export default withStyles(styles)(Useful);
