import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  InfoContainerCenter: {
    // width: "100%",
    paddingTop: theme.spacing,
    border: "1px solid transparent",
    borderColor: theme.palette.primary.light,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  }
});

class InfoContainerCenter extends Component {
  render() {
    const { classes, info } = this.props;
    return (
      <div className={classes.InfoContainerCenter}>
        <Typography variant="body1" gutterBottom >{info.title}</Typography>
        <div className={classes.InfoWrapper}>
          {info.points.map(point => {
            return (
              <div className={classes.Point} key={point}>
                <Typography variant="body2" color="textSecondary">
                  {point}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InfoContainerCenter);
