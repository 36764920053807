import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const dataEnglish = [];

const dataSpanish = [];

const styles = theme => ({
  Welcome: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10)
    },
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  RSVPCardTypo: {
    display: "inline-block",
    marginBottom: theme.spacing(2)
  },
  ReplyCard: {
    border: "solid 2px",
    borderColor: theme.palette.primary.main,
    margin: theme.spacing(1),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
    // border: "solid 1px",
    // borderColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "flex-start",
    textAlign: "left",
    flexDirection: "column",
  },
  Button: {
    borderRadius: 0,
    margin: theme.spacing(1),
    alignSelf: "flex-end"
  },
});

function langSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return dataEnglish;
    case "SPANISH":
      return dataSpanish;
    default:
      return dataEnglish;
  }
}

class Welcome extends Component {
  constructor() {
    super();
    this.state = {
      canNames: '',
      values: null,
      notChanging: true
    }
  }

  handleChange(event) {
    console.log(event.target.name, event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleTypingCan(e) {
    this.setState({
      canNames: e.target.value
    })
  }

  handleTypingCannot(e) {
    this.setState({
      cannotNames: e.target.value
    })
  }

  handleTypingAnything(e) {
    this.setState({
      anything: e.target.value
    })
  }

  handleChangeClick() {
    this.setState({
      notChanging: false
    })
  }

  handleSubmitClick() {
    const { canNames, cannotNames, anything } = this.state;
    this.props.handleSubmit( canNames, cannotNames, anything);
  }

  renderRSVPCard() {
    const { classes, lang, reply, sessionReply } = this.props;
    const { RSVPValue, canNames, cannotNames, anything } = this.state;
    return (
      <div className={classes.ReplyCard}>
        <Typography className={classes.RSVPCardTypo} gutterBottom>
          Thanks,{" "}<Input
            placeholder="  name(s)"
            className={classes.input}
            value={canNames}
            onChange={this.handleTypingCan.bind(this)}
            inputProps={{
              'aria-label': 'Description',
            }}
          />{" "}can come to the wedding.
        </Typography>
        <Typography className={classes.RSVPCardTypo}>
          Sorry but{" "}<Input
            placeholder="  name(s)"
            className={classes.input}
            value={cannotNames}
            onChange={this.handleTypingCannot.bind(this)}
            inputProps={{
              'aria-label': 'Description',
            }}
          />{" "}cannot come to the wedding.
        </Typography>
        <Input
          className={classes.RSVPCardTypo}
          id="standard-full-width"
          placeholder="  And anything else we need to know?"
          fullWidth
          value={anything}
          onChange={this.handleTypingAnything.bind(this)}
        />
        <div className={classes.Button}>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleSubmitClick.bind(this)}
            size="medium"
          >
            Send
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { classes, fetching, lang, reply, sessionReply, rsvpError } = this.props;
    const { notChanging } = this.state;
    if (reply.replied && notChanging) return (
      <div className={classes.Welcome}>
        <Typography>Thanks, you have replied that{ !reply.can || reply.can === 'undefined' ? "" : ` ${reply.can} can come, ` }{ !reply.cannot || reply.cannot === 'undefined' ? "" : ` ${reply.cannot} cannot come. ` } Please click below to change your response.</Typography>
        <div className={classes.Button}>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleChangeClick.bind(this)}
            size="medium"
          >
            Change RSVP
          </Button>
        </div>
      </div>
    );
    if (sessionReply) return <Typography>Thanks</Typography>
    return (
      <div className={classes.Welcome}>
        {rsvpError ? null : (fetching) ? <CircularProgress /> : this.renderRSVPCard.call(this)}
      </div>
    );
  }
}

export default withStyles(styles)(Welcome);
