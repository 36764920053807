import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import classname from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Zoom1 from "./Zoom-1";
import Zoom2 from "./Zoom-2";
import Zoom3 from "./Zoom-3";
import Zoom4 from "./Zoom-4";
import Zoom5 from "./Zoom-5";
import Zoom6 from "./Zoom-6";

const imageRatio = 0.72;
const baseWidth = 360;
const wideWidth = 600;

const styles = theme => ({
  Zoomer: {
    cursor: "pointer",
    position: "relative",
    width: `${baseWidth}px`,
    height: `${baseWidth * imageRatio}px`,
    overflow: "hidden",
    transition: "top 1s, left 1s, height 1s, width 1s",
    [theme.breakpoints.up("md")]: {
      width: `${wideWidth}px`,
      height: `${wideWidth * imageRatio}px`
    }
  },
  ZoomIn1: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "top 1s, left 1s, height 1s, width 1s"
  },
  Zoomed1: {
    top: "-245%",
    left: "-128%",
    width: "410%",
    height: "410%",
    transition: "top 1s, left 1s, height 1s, width 1s"
  },
  ZoomOut2: {
    position: "absolute",
    top: "58.9%",
    left: "31.4%",
    width: "24.4%",
    height: "24.4%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 0
  },
  ZoomIn2: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 1
  },
  Zoomed2: {
    top: "-339.5%",
    left: "-350.2%",
    width: "758.6%",
    height: "758.3%",
    transition: "top 1s, left 1s, height 1s, width 1s"
  },
  ZoomOut3: {
    position: "absolute",
    top: "44.8%",
    left: "46.2%",
    width: "13.2%",
    height: "13.2%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 0
  },
  ZoomIn3: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 1
  },
  Zoomed3: {
    top: "-565.4%",
    left: "-484.6%",
    width: "769.2%",
    height: "769.2%",
    transition: "top 1s, left 1s, height 1s, width 1s"
  },
  ZoomOut4: {
    position: "absolute",
    top: "73.5%",
    left: "63%",
    width: "13%",
    height: "13%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 0
  },
  ZoomIn4: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 1
  },
  Zoomed4: {
    top: "-376%",
    left: "-481%",
    width: "833.3%",
    height: "833.3%",
    transition: "top 1s, left 1s, height 1s, width 1s"
  },
  ZoomOut5: {
    position: "absolute",
    top: "45.1%",
    left: "57.7%",
    width: "12%",
    height: "12%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 0
  },
  ZoomIn5: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 1
  },
  Zoomed5: {
    top: "-349%",
    left: "-438%",
    width: "758.6%",
    height: "758.6%",
    transition: "top 1s, left 1s, height 1s, width 1s"
  },
  ZoomOut6: {
    position: "absolute",
    top: "46%",
    left: "57.7%",
    width: "13.2%",
    height: "13.2%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 0
  },
  ZoomIn6: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "top 1s, left 1s, height 1s, width 1s, opacity 1s",
    opacity: 1
  },
  ZoomHide: {
    opacity: 0
  },
  Click: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: "100%",
    opacity: 0.5,
    zIndex: 10000
  },
  Clicked: {
    visibility: "hidden"
  },
  Plus: {
    height: "100%",
    width: "50%",
    top: 0,
    right: 0,
    position: "absolute",
    zIndex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: "5em",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  Minus: {
    height: "100%",
    width: "50%",
    top: 0,
    left: 0,
    position: "absolute",
    zIndex: 1,
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "5em",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
});

class MapZoomer extends Component {
  constructor() {
    super();
    this.state = {
      zoom: 4,
      clicked: true
    };
  }
  toggleZoom() {
    const { zoom, clicked } = this.state;
    if (!clicked) return this.setState({ clicked: true });
    if (zoom === 5) return;
    this.setState({
      zoom: zoom + 1
    });
  }

  toggleZoomOut() {
    const { zoom, clicked } = this.state;
    if (!clicked) return this.setState({ clicked: true });
    if (zoom === 0) return this.setState({ zoom: 0 });
    this.setState({
      zoom: zoom - 1
    });
  }

  render() {
    const { zoom, clicked } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.Zoomer}>
      <div className={classes.Minus} onClick={this.toggleZoomOut.bind(this)}>-</div>
        <div
          className={classname(classes.Click, { [classes.Clicked]: clicked })}
        >
          <Typography variant="h3">click to zoom</Typography>
        </div>
        <div
          className={classname(classes.ZoomIn1, {
            [classes.Zoomed1]: zoom > 0
          })}
        >
          <Zoom1 />
        </div>
        <div
          className={classname(classes.ZoomOut2, {
            [classes.ZoomIn2]: zoom > 0,
            [classes.Zoomed2]: zoom > 1
          })}
        >
          <Zoom2 />
        </div>
        <div
          className={classname(classes.ZoomOut3, {
            [classes.ZoomIn3]: zoom > 1,
            [classes.Zoomed3]: zoom > 2
          })}
        >
          <Zoom3 />
        </div>
        <div
          className={classname(classes.ZoomOut4, {
            [classes.ZoomIn4]: zoom > 2,
            [classes.Zoomed4]: zoom > 3
          })}
        >
          <Zoom4 />
        </div>
        <div
          className={classname(classes.ZoomOut5, {
            [classes.ZoomIn5]: zoom > 3,
            [classes.Zoomed5]: zoom > 4
          })}
        >
          <Zoom5 />
        </div>
        <div
          className={classname(classes.ZoomOut6, {
            [classes.ZoomIn6]: zoom > 4,
            [classes.Zoomed6]: zoom > 5
          })}
        >
          <Zoom6 />
        </div>
        <div className={classes.Plus} onClick={this.toggleZoom.bind(this)}>+</div>
      </div>
    );
  }
}

export default withStyles(styles)(MapZoomer);
