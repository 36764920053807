import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Helmet } from "react-helmet";
import Invite from "../components/MainInviteComponent";
import withRoot from "../withRoot";
import qs from "query-string";
import request from "superagent";

const styles = theme => ({
  root: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white"
  },
  InviteWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  Buttons: {
    marginBottom: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  Button: {
    marginLeft: theme.spacing(1),
    borderRadius: 0
  },
  Details: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3)
    }
  }
});

class MainInvite extends React.Component {
  renderButtons() {
    const { classes, width, rsvping } = this.props;
    return (
      <div className={classes.Buttons}>
        <div className={classes.Button}>
          <Button
            variant="outlined"
            color="primary"
            size={width === "lg" || width === "md" ? "medium" : "small"}
            onClick={this.props.handleRSVPing}
          >
            RSVP and useful information
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { classes, name } = this.props;
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Save the date</title>
        </Helmet>
        <div className={classes.Details}>
          {this.renderButtons.call(this)}
        </div>
        <div className={classes.InviteWrapper}>
          <Invite name={name || null} />
        </div>
      </div>
    );
  }
}

export default withRoot(withWidth()(withStyles(styles)(MainInvite)));
