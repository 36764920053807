import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import MUILink from "@material-ui/core/Link";

const dataEnglish = [

];

const dataSpanish = [
];

const styles = theme => ({
  RegistryTab: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10)
    },
  },
  MobileTitle: {
    display: "block",
    textAlign: "left",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  Button: {
    marginBottom: theme.spacing(4)
  }
});

function langSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return dataEnglish;
    case "SPANISH":
      return dataSpanish;
    default:
      return dataEnglish;
  }
}

class RegistryTab extends Component {
  render() {
    const { classes, lang } = this.props;
    return (
      <div className={classes.RegistryTab}>
        <Typography className={classes.MobileTitle} gutterBottom variant="h5">
          Gift list
        </Typography>
        <Typography gutterBottom variant="body1">
          Can't wait to celebrate our wedding with you. If you would like to give us a present, you can find our list on Prezola
        </Typography>
        <div className={classes.Button}>
          <MUILink
            target="_blank"
            rel="noopener noreferrer"
            href={"https://prezola.com/wishlists/10211722/"}
            className={classes.link}
          >
            <Button
            variant="outlined"
            color="primary"
            >
            Prezola
            </Button>
          </MUILink>
        </div>
        <Typography gutterBottom variant="body1">
          Itaú cuenta $ 3808052
        </Typography>
        <Typography gutterBottom variant="body1">
          Itaú cuenta US$ 1826850
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(RegistryTab);
