import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  Activity: {
    width: "100%",
    paddingTop: theme.spacing(1),
    borderTop: "1px solid transparent",
    borderTopColor: "rgba(0,0,0,.4)"
  },
  ActionWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row "
    }
  },
  DateWrapper: {
    textAlign: "left",
    padding: theme.spacing(1)
  },
  Time: {
    minWidth: 100,
    padding: theme.spacing(1),
    textAlign: "left"
  },
  Action: {
    padding: theme.spacing(1),
    textAlign: "left"
  }
});

class Activity extends Component {
  render() {
    const { classes, date, actions } = this.props;
    return (
      <div className={classes.Activity}>
        <Typography
          className={classes.DateWrapper}
          variant="subtitle2"
          gutterBottom
        >
          {date}
        </Typography>
        <div className={classes.InfoWrapper}>
          {actions.map(item => {
            return (
              <div className={classes.ActionWrapper} key={item.what}>
                <Typography className={classes.Time} variant="body1">
                  {item.when}
                </Typography>
                <div className={classes.Action}>
                  <Typography variant="body1">{item.what}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {item.where}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {item.info}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Activity);
