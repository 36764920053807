import React from "react";
import Typography from "@material-ui/core/Typography";

export default function PaddedTypo(props) {
  return (
    <div
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        marginLeft: 8,
        marginRight: 8,
        marginBottom: 16
      }}
    >
      <Typography {...props}>{props.children}</Typography>
    </div>
  );
}
