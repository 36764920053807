import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/typography";
import Activity from "./Activity";

const dataEnglish = [
  {
    date: "Friday 6th March",
    actions: [
      {
        when: "2:00 PM",
        what: "Jose Ignacio, La Brava Beach",
        where: "For those travelling from UK and in Jose Ignacio on the 6th, we will be at Jose Ignacio’s La Brava Beach - please feel free to join us for a picnic",
        info:
          ""
      }
    ]
  },
  {
    date: "Saturday 7th March",
    actions: [
      {
        when: "4:30 PM",
        what: "Church Ceremony",
        where: "Capilla Santa Teresita, Jose Ignacio",
        info: null
      },
      {
        when: "5:00 PM",
        what: "Bride Arrives",
        where: null,
        info: null
      },
      {
        when: "6:00 PM",
        what: "Reception",
        where: "La Huella, Jose Ignacio",
        info:
          "No transport needs to be arranged between the church ceremony and reception, it is a short walk away"
      },
      {
        when: "4:00 AM",
        what: "Carriages",
        where: null
      }
    ]
  }
];

const dataSpanish = [
  {
    date: "Viernes 6 de marzo",
    actions: [
      {
        when: "2:00 PM",
        what: "Picnic en la playa",
        where: "José Ignacio, Playa La Mansa",
        info:
          "Estaremos en la playa La Mansa de José Ignacio. No dude en visitarnos"
      }
    ]
  },
  {
    date: "Sábado 7 de marzo",
    actions: [
      {
        when: "4:00 PM",
        what: "Ceremonia de la Iglesia",
        where: "Iglesia Santa Teresita, José Ignacio",
        info: null
      },
      {
        when: "4:30 PM",
        what: "la novia llega",
        where: null,
        info: null
      },
      {
        when: "6:00 PM",
        what: "Recepción",
        where: "La Huella, José Ignacio",
        info: "Esto es un corto paseo de la iglesia"
      },
      {
        when: "4:00 AM",
        what: "Carruajes",
        where: null,
        info: "Por favor, prepárate para el final tardío"
      }
    ]
  },
  {
    date: "Domingo 8 de marzo",
    actions: [
      {
        when: "2:30 PM",
        what: "dia de la playa",
        donde: "Playa La Mansa, José Ignacio",
        info: "Ven a intercambiar historias en la playa, BYO"
      }
    ]
  }
];

const styles = theme => ({
  Activities: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
      marginBottom: 160
    },
    borderBottom: "1px solid transparent",
    borderBottomColor: "rgba(0,0,0,.4)",
  },
  MobileTitle: {
    display: "block",
    width: "100%",
    textAlign: "left",
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
});

function langSwitcher(lang) {
  switch (lang) {
    case "ENGLISH":
      return dataEnglish;
    case "SPANISH":
      return dataSpanish;
    default:
      return dataEnglish;
  }
}

class Activities extends Component {
  render() {
    const { classes, lang } = this.props;
    return (
      <div className={classes.Activities}>
        <Typography className={classes.MobileTitle} gutterBottom variant="h5">
          Timings
        </Typography>
        {langSwitcher(lang).map(item => (
          <Activity key={item.date} date={item.date} actions={item.actions} />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(Activities);
